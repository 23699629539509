import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import SeatAvatar from 'components/SeatAvatar'
import { AvailableSeatAvatar } from 'components/SeatAvatar/AvailableSeatAvatar'
import { PendingInviteSeatAvatar } from 'components/SeatAvatar/PendingInviteSeatAvatar'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import { TeamMembersPageSubscriptionSeatFragment } from 'gql'

import { cn } from 'utils/tailwind'
import { displaySuccessToast } from 'utils/toastService'

interface SeatActionsProps {
  seat: TeamMembersPageSubscriptionSeatFragment
  onAssignCohortPassAction(seat: SeatActionsProps['seat']): void
  onSeatAssignAction(seat: SeatActionsProps['seat']): void
  hasCohortAccess: boolean
  isProvisionedByScim: boolean
}

const SeatActions = ({ seat }: SeatActionsProps) => {
  return (
    <div className="flex justify-end gap-4">
      <div className="w-8 shrink-0">
        {seat.subscriptionMember ? (
          <DropdownContextMenu
            positions={['left']}
            dataTest={`seat-${seat.id}-dropdown-button`}
            dismissOnClick
          >
            <DropdownContextMenu.DropdownItem
              data-test="dropdown-context-menu-unassign-assign-seat"
              text={'Assign'}
            />
          </DropdownContextMenu>
        ) : null}
      </div>
    </div>
  )
}
const ArrowDownIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1077_62835)">
      <path
        d="M11.5123 3.81982L6.2595 9.07212C6.2254 9.10627 6.1849 9.13332 6.1404 9.15182C6.0958 9.17032 6.048 9.17982 5.9998 9.17982C5.9516 9.17982 5.9038 9.17032 5.8592 9.15182C5.8147 9.13332 5.7742 9.10627 5.7401 9.07212L0.487305 3.81982"
        stroke="#2D2F2F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1077_62835">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export interface SeatsListProps
  extends Omit<SeatActionsProps, 'seat' | 'hasCohortAccess'> {
  seats: TeamMembersPageSubscriptionSeatFragment[]
  subscriptionHasDomainName: boolean
  recentlyUpdatedSeat: TeamMembersPageSubscriptionSeatFragment | null
  isProvisionedByScim: boolean
}

const highlightRecentlyUpdatedSeatRow = ({
  seat,
  tableRef
}: {
  seat?: TeamMembersPageSubscriptionSeatFragment | null
  tableRef?: React.RefObject<HTMLTableElement> | null
}) => {
  if (seat) {
    const updatedSeatRow =
      tableRef?.current && tableRef.current.querySelector(`[data-id='${seat.id}']`)

    if (updatedSeatRow) {
      updatedSeatRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

      updatedSeatRow.classList.add('bg-rb-blue-50')
      setTimeout(() => {
        updatedSeatRow.classList.remove('bg-rb-blue-50')
      }, 5000)
    }
  }
}

export const SeatsList = ({
  seats,
  subscriptionHasDomainName,
  onAssignCohortPassAction,
  onSeatAssignAction,
  recentlyUpdatedSeat,
  isProvisionedByScim
}: SeatsListProps) => {
  const history = useHistory()
  const tdClass = 'px-4 py-2 text-xs align-middle h-full'
  const [randomRoles, setRandomRoles] = useState<string[]>([])
  const [randomSeats, setRandomSeats] = useState<string[]>([])
  const [randomGroups, setRandomGroups] = useState<string[]>([])
  const [randomDays, setRandomDays] = useState<string[]>([]) // New state for random days

  const [checkedSeats, setCheckedSeats] = useState<Set<string>>(
    new Set(seats.slice(0, 7).map((seat) => seat.id))
  ) // Default to select all

  const getSeatAvatar = (
    subscriptionMember: TeamMembersPageSubscriptionSeatFragment['subscriptionMember']
  ) => {
    const avatarSize = 38
    if (subscriptionMember && subscriptionMember.status === 'pending') {
      return (
        <PendingInviteSeatAvatar
          emailStyles="text-sm font-semibold text-[#757B74] truncate ... w-72"
          email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
          avatarSize={avatarSize}
        />
      )
    } else if (subscriptionMember) {
      const nameToDisplay =
        subscriptionMember.user.fullName || subscriptionMember.user.email
      const isCurrentUser = subscriptionMember.user.isCurrentUser
      const isAssistantAdmin = subscriptionMember.user.is.assistantAdmin

      return (
        <SeatAvatar
          avatarSize={avatarSize}
          nameStyles="text-xs truncate flex items-center font-sans"
          emailStyles="text-xs font-medium text-[#757B74] truncate"
          memberName={`${nameToDisplay} ${isCurrentUser ? '(You)' : ''}`}
          assistantAdmin={isAssistantAdmin}
          email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
          isWorkEmail={!!subscriptionMember.user.workEmail}
          subscriptionHasDomainName={subscriptionHasDomainName}
          avatarUrl={subscriptionMember.user.profile.avatarUrl}
        />
      )
    }

    return (
      <AvailableSeatAvatar avatarSize={avatarSize - 3} avatarWrapperSize={avatarSize} />
    )
  }
  const handleAssign = () => {
    displaySuccessToast({ message: "You've successfully assigned goal." })
    history.push('/goals')
  }

  const toggleSeatSelection = (seatId: string) => {
    setCheckedSeats((prev) => {
      const newCheckedSeats = new Set(prev)
      if (newCheckedSeats.has(seatId)) {
        newCheckedSeats.delete(seatId)
      } else {
        newCheckedSeats.add(seatId)
      }
      return newCheckedSeats
    })
  }

  // Highlight recently updated seat
  const tableRef = useRef<HTMLTableElement>(null)

  const thClass = 'px-10 pt-10 text-sm font-semibold pb-4'
  const UncheckedBox = (
    <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
  )
  const CheckedBox = (
    <SVGIcon name="checkbox-ticked" fill="#080A0A" width="20" height="20" />
  )
  const toggleAllSeatsSelection = () => {
    if (checkedSeats.size === seats.length) {
      setCheckedSeats(new Set()) // Deselect all if all are selected
    } else {
      setCheckedSeats(new Set(seats.map((seat) => seat.id))) // Select all
    }
  }

  useEffect(() => {
    highlightRecentlyUpdatedSeatRow({ seat: recentlyUpdatedSeat, tableRef })
  }, [recentlyUpdatedSeat])
  useEffect(() => {
    // Generate random values once when the component mounts
    const roles = ['Team member', 'Admin']
    const seatTypes = ['Free', 'Premium']
    const groups = ['Product design', 'Engineering', 'Product']

    setRandomRoles(seats.map(() => roles[Math.floor(Math.random() * roles.length)]))
    setRandomSeats(
      seats.map(() => seatTypes[Math.floor(Math.random() * seatTypes.length)])
    )
    setRandomDays(seats.map(() => `${Math.floor(Math.random() * 12) + 2} days ago`)) // Random days

    setRandomGroups(seats.map(() => groups[Math.floor(Math.random() * groups.length)]))
  }, [seats]) // D

  return (
    <div className="min-h-0 max-h-[600px] ">
      <table data-test="seat-list-table" className="min-w-full  divide-rb-gray-100">
        <tr className="sticky top-0 z-10 bg-white text-left text-[#757B74] border-b">
          <td className={cn(tdClass, 'text-rb-gray-400 font-sans font-semibold')}>
            <div
              className="flex items-center gap-2 h-full"
              onClick={toggleAllSeatsSelection}
            >
              {checkedSeats.size === seats.length ? CheckedBox : UncheckedBox}
            </div>
          </td>
          <td className={cn(tdClass, 'text-rb-gray-400 font-sans font-semibold')}>
            <div className="flex items-center gap-2 h-full">
              Name{' '}
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1077_62888)">
                  <path
                    d="M4 0.25V7.75"
                    stroke="#2D2F2F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.5 4.25L4 7.75L7.5 4.25"
                    stroke="#2D2F2F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1077_62888">
                    <rect width="8" height="8" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </td>
          <td className={cn(tdClass, 'text-rb-gray-400 font-sans font-semibold')}>
            Role
          </td>
          <td className={cn(tdClass, 'text-rb-gray-400 font-sans font-semibold')}>
            Seat
          </td>

          <td className={cn(tdClass, 'text-rb-gray-400 font-sans font-semibold')}>
            Group
          </td>
          <td className={cn(tdClass, 'text-rb-gray-400 font-sans font-semibold')}>
            Last active
          </td>

          <td className={`text-white ${thClass}`}>Actions Menu</td>
        </tr>
        {seats.slice(0, 7).map((seat, index) => {
          const { subscriptionMember, hasCohortAccess, id: seatId } = seat
          return (
            <tr
              key={seatId}
              data-id={seatId}
              data-test={`${
                subscriptionMember ? 'occupied-seat' : 'unoccupied-seat'
              }-${seatId}`}
              className="h-16"
            >
              <td className={tdClass}>
                <div onClick={() => toggleSeatSelection(seatId)}>
                  {checkedSeats.has(seatId) ? CheckedBox : UncheckedBox}
                </div>
              </td>
              <td className={tdClass}>{getSeatAvatar(subscriptionMember)}</td>
              <td data-test={`cohort-access-seat-${seatId}`} className={cn(tdClass)}>
                <div className="flex gap-2 items-center justify-start text-xs">
                  {randomRoles[index]}
                  <ArrowDownIcon />
                </div>
              </td>
              <td className={tdClass}>
                <div className="flex gap-2 items-center justify-start text-xs">
                  {randomSeats[index]}
                  <ArrowDownIcon />
                </div>
              </td>
              <td className={tdClass}>
                <div className="flex gap-2 items-center justify-start text-xs">
                  {randomGroups[index]}
                  <ArrowDownIcon />
                </div>
              </td>
              <td className={tdClass}>{randomDays[index]}</td>
              <td className={tdClass}>
                <SeatActions
                  seat={seat}
                  onAssignCohortPassAction={onAssignCohortPassAction}
                  onSeatAssignAction={onSeatAssignAction}
                  hasCohortAccess={hasCohortAccess}
                  isProvisionedByScim={isProvisionedByScim}
                />
              </td>
            </tr>
          )
        })}
      </table>
      <Button className="mt-8" variant="outline" size="small" onClick={handleAssign}>
        Assign to {checkedSeats.size === 1 ? '1 person' : `${checkedSeats.size} people`}
      </Button>
    </div>
  )
}
