import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import { useGlobalChatTracking } from 'domains/Ai/GlobalChatTrackingProvider'
import { markdownToHtml } from 'domains/Ai/helpers'

import { Loading } from 'components'
import Button from 'components/Button'
import { PaperAndPencilIcon } from 'components/icons/PaperAndPencilIcon'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { AiDocument, useAiExchangeChatIdForExtIdLazyQuery } from 'gql'

import { buildCourseDashboardLinkFromPath } from 'utils/courseUtils'
import notifyError from 'utils/errorNotifier'

interface DraftArtifactRowsProps {
  aiDocuments: AiDocument[]
  artifacts: any[] // Replace 'any' with the correct type for your artifacts
  courseSlug: string
  courseSessionId?: string | null
}

export const DraftArtifactCards = ({
  aiDocuments,
  artifacts,
  courseSlug,
  courseSessionId
}: DraftArtifactRowsProps) => {
  const {
    chatId,
    messages,
    endSession,
    setMode,
    openChatAndSendMessage,
    loadSession,
    setIsChatOpen,
    populateEditor
  } = useGlobalChat()
  const { trackChatDraftStarted, trackChatDraftClicked } = useGlobalChatTracking()
  const [isLoadingExistingDraftChat, setIsLoadingExistingDraftChat] = useState(false)
  const [exchangeChatIdForExtId] = useAiExchangeChatIdForExtIdLazyQuery()

  const navigate = useNavigate()

  const navigateToArtifact = useCallback(
    (artifact: any) => {
      navigate(
        buildCourseDashboardLinkFromPath({
          courseSlug,
          sessionId: courseSessionId,
          artifactSlug: artifact.slug
        }),
        {
          state: {
            backTo: [{ label: 'Course Home', path: window.location.pathname }],
            fromCourse: true
          }
        }
      )
    },
    [navigate, courseSlug, courseSessionId]
  )

  const handleCreateDraftCtaClick = (artifact: any) => {
    const templateName = artifact.draftTemplateName
    const labelName = artifact.draftLabelName
    if (messages.length > 0) endSession()

    trackChatDraftStarted({
      chatId: chatId,
      templateName: templateName,
      location: 'course_dashboard'
    })
    setMode({
      mode: 'document_generation',
      modeOptions: { command: 'draft', template_name: templateName, label: labelName }
    })
    openChatAndSendMessage(`Help me draft a ${labelName} document`, 'artifact_viewer', {
      mode: 'document_generation',
      modeOptions: { command: 'draft', template_name: templateName, label: labelName }
    })

    navigateToArtifact(artifact)
  }

  const handleViewDraftCtaClick = useCallback(
    async ({ artifact, existingDraft }: { artifact: any; existingDraft: AiDocument }) => {
      const htmlString = await markdownToHtml(existingDraft.content ?? '')

      setIsLoadingExistingDraftChat(true)

      const { data: chatIdExchange } = await exchangeChatIdForExtId({
        variables: { chatId: existingDraft.aiChatId }
      })

      if (!chatIdExchange?.exchangeChatIdForExtId) {
        notifyError('Failed to exchange chat id for ext id', {
          chatId: existingDraft.aiChatId
        })
        setIsLoadingExistingDraftChat(false)
        return
      }

      await loadSession(chatIdExchange.exchangeChatIdForExtId)

      setIsChatOpen(true)
      setIsLoadingExistingDraftChat(false)
      trackChatDraftClicked({
        chatId,
        draftId: existingDraft.id,
        templateName: existingDraft.templateName || '',
        location: 'course_dashboard'
      })

      populateEditor({
        draft: existingDraft,
        htmlString
      })

      navigateToArtifact(artifact)
    },
    [
      setIsChatOpen,
      populateEditor,
      loadSession,
      chatId,
      trackChatDraftClicked,
      exchangeChatIdForExtId,
      navigateToArtifact
    ]
  )

  return (
    <div className="mb-8 flex flex-col gap-4 hidden xs:flex">
      {sortArtifactsByDraftStatus({
        artifacts: artifactsWithDrafts({ artifacts }),
        aiDocuments
      }).map((artifact: any) => (
        <RenderArtifactRow
          key={artifact.id}
          navigateToArtifact={navigateToArtifact}
          existingDraft={
            // we send the documents back in descending order of creation
            // so the first one is the most recent
            aiDocuments.find((doc) => doc.templateName === artifact.draftTemplateName) ||
            null
          }
          isLoadingExistingDraftChat={isLoadingExistingDraftChat}
          artifact={artifact}
          handleCreateDraftCtaClick={handleCreateDraftCtaClick}
          handleViewDraftCtaClick={handleViewDraftCtaClick}
        />
      ))}
    </div>
  )
}

const RenderArtifactRow = ({
  existingDraft,
  navigateToArtifact,
  isLoadingExistingDraftChat,
  artifact,
  handleCreateDraftCtaClick,
  handleViewDraftCtaClick
}: {
  existingDraft: AiDocument | null
  isLoadingExistingDraftChat: boolean
  artifact: any
  handleCreateDraftCtaClick: (artifact: any) => void
  handleViewDraftCtaClick: ({
    artifact,
    existingDraft
  }: {
    artifact: any
    existingDraft: AiDocument
  }) => void
  navigateToArtifact: (artifact: any) => void
}) => {
  return (
    <div className="flex items-center">
      <div
        onClick={() => navigateToArtifact(artifact)}
        onKeyDown={(e) => e.key === 'Enter' && navigateToArtifact(artifact)}
        role="button"
        tabIndex={0}
        className="flex items-center group grow cursor-pointer"
      >
        <div className="flex items-center p-4 mr-6 border border-rb-gray-100 rounded-lg group-hover:bg-rb-orange-25">
          <PaperAndPencilIcon fill="#2D2F2F" />
        </div>
        <div className="w-[300px] flex items-center" title={artifact.title}>
          <RfParagraphSmall className="font-semibold truncate">
            {artifact.title}
          </RfParagraphSmall>
        </div>
      </div>
      <div>
        {existingDraft ? (
          <Button
            variant="text-only"
            size="small"
            className="h-10 px-3 font-semibold cursor-pointer"
            isLoadingSpinner={isLoadingExistingDraftChat}
            onClick={() => {
              handleViewDraftCtaClick({ artifact, existingDraft })
            }}
          >
            {isLoadingExistingDraftChat ? (
              <Loading className="w-2 h-2" />
            ) : (
              'View my draft'
            )}
          </Button>
        ) : (
          <Button
            color="teal"
            size="medium"
            className="h-10 cursor-pointer justify-center font-semibold px-4"
            onClick={() => handleCreateDraftCtaClick(artifact)}
          >
            Create draft
          </Button>
        )}
      </div>
    </div>
  )
}

const artifactsWithDrafts = ({ artifacts }: { artifacts: any[] }) => {
  return artifacts.filter((a) => !!a.draftTemplateName)
}

/**
 * Returns artifacts without a draft template
 */
export const artifactsWithoutDrafts = ({ artifacts }: { artifacts: any[] }) => {
  return artifacts.filter((a) => !a.draftTemplateName)
}

/**
 * Sorts artifacts based on their draft status.
 *
 * The sorting order is as follows:
 * 1. Artifacts with a user-created draft
 * 2. Artifacts with a draft template
 * 3. Artifacts without a draft
 */
export const sortArtifactsByDraftStatus = ({
  artifacts,
  aiDocuments
}: {
  artifacts: any[]
  aiDocuments: AiDocument[]
}) => {
  return [...artifacts].sort((a, b) => {
    const aDraft = aiDocuments.find((doc) => doc.templateName === a.draftTemplateName)
    const bDraft = aiDocuments.find((doc) => doc.templateName === b.draftTemplateName)

    // 1. Artifacts with a user-created draft
    if (aDraft && !bDraft) return -1
    if (!aDraft && bDraft) return 1

    // 2. Artifacts with a draft template
    if (a.draftTemplateName && !b.draftTemplateName) return -1
    if (!a.draftTemplateName && b.draftTemplateName) return 1

    // 3. If both are equal in terms of draft status, maintain original order
    return 0
  })
}
