import Button from 'components/Button'

export const PremadeGoals = ({ args }: any) => {
  const {
    currentUser,
    aiLearningGoalsDefaultRecommendations,
    handlePremadeGoalSelection
  } = args

  let data: any[] =
    currentUser?.is?.planManager || currentUser?.is?.teamSubscriptionOwner
      ? aiLearningGoalsDefaultRecommendations.defaultPlanManager
      : aiLearningGoalsDefaultRecommendations.default

  if (
    Object.prototype.hasOwnProperty.call(
      aiLearningGoalsDefaultRecommendations,
      currentUser?.id
    )
  ) {
    data = aiLearningGoalsDefaultRecommendations[currentUser!.id]
  }

  return (
    <div className="grid col-span-3 grid-flow-col gap-8 my-8 w-full">
      {data.map((d, index) => (
        <div
          className="font-sans flex flex-col p-6 rounded-2xl w-[339px] border-1.5 border-solid bg-white border-rb-gray-100 justify-between"
          key={index}
        >
          <div>
            <div className="bg-rb-blue w-fit text-xs font-medium text-white p-[5px] rounded-[3px] flex items-center justify-center">
              FOR YOU
            </div>

            <div className="font-semibold text-rb-gray-400 mb-4 text-base my-3 ">
              {d.prompt}
            </div>
          </div>
          <div className="flex items-end justify-between">
            <div className="font-normal text-sm text-rb-gray-300">30 minutes, Weekly</div>

            <Button
              size="x-small"
              shape="rounded-full"
              className=""
              onClickCapture={() => handlePremadeGoalSelection(d)}
            >
              Select goal
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}
