import { useEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { ContentPercentComplete } from 'components/cards/Content/utils'

import { MergedContent, useTrackServerEventMutation } from 'gql'

import useConsumeProgress from 'hooks/useConsumeProgress'

import { CourseRelatedIdentifierProperties } from 'utils/contentTrackingUtils'
import { getAnonymousId } from 'utils/tracking/segment'

interface UnitProgressConsumerProps {
  contentBlock?: MergedContent | null
  courseRelatedIdentifiers?: CourseRelatedIdentifierProperties
}

export const CourseBlockContentBlockProgressConsumer = ({
  contentBlock,
  courseRelatedIdentifiers
}: UnitProgressConsumerProps) => {
  const [consumeProgress, setConsumeProgress] = useState(0)
  const [completeTracked, setCompleteTracked] = useState(false)

  const currentConsumeProgress = useConsumeProgress({
    id: 'content-block-container',
    scrollableId: 'page'
  })

  const [trackServerEvent] = useTrackServerEventMutation()

  const TrackContentCompleted = (
    contentBlock: MergedContent,
    progressChangeType: string
  ) => {
    if (contentBlock?.id) {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Completed - Server',
            properties: {
              content_type: 'flex_content',
              content_title: contentBlock?.header,
              content_sanity_id: contentBlock?.id,
              completion_type: progressChangeType,
              related_identifiers: courseRelatedIdentifiers
            }
          }
        }
      })
    }
  }

  const debouncedTrackContentCompleted = useRef(
    debounce(750, TrackContentCompleted)
  ).current

  const handleConsumeProgressChange = (
    consumeProgressVal: number,
    progressChangeType: string
  ) => {
    if (consumeProgressVal > consumeProgress) {
      setConsumeProgress(consumeProgressVal)
    }

    if (consumeProgressVal > ContentPercentComplete.NinetyPercent) {
      if (!completeTracked) {
        debouncedTrackContentCompleted(contentBlock)
        debouncedTrackContentCompleted(contentBlock, progressChangeType)
        setCompleteTracked(true)
      }
    }
  }

  useEffect(() => {
    handleConsumeProgressChange(currentConsumeProgress, 'scroll')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConsumeProgress, contentBlock])

  return <></>
}

export default CourseBlockContentBlockProgressConsumer
